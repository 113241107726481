import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AnnualReview } from "library/annualReview";
import { dispatch } from "store";
import { loadWithCode } from "./application";
import api from "library/api";

const createAnnualReview = createAsyncThunk("annualReviews/create",	async () => {
	const application = await api.AnnualReviews.create();
	if (!application) throw Error("Failed to create annual review");
	
	await dispatch(loadWithCode(application.code));
	return application;
});

const annualReview = createSlice({
	name: "annualReviewsState",
	initialState: {loading: false, value: new AnnualReview()},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createAnnualReview.pending, (state) =>
			({ value: state.value, loading: true }));
		builder.addCase(createAnnualReview.fulfilled, (state) =>
			({ value: state.value, loading: false }));
		builder.addCase(createAnnualReview.rejected, (state) =>
			({ value: state.value, loading: false }));
	}
});

export default annualReview.reducer;
export { createAnnualReview };
