import {useDispatch, useSelector} from "store";
import {useEffect} from "react";
import {getProviderAnnouncementsFor} from "store/slices/announcements";
import {Announcement} from "library/dashboard/announcement";

const useAnnouncements = (): Announcement[] | undefined => {
	const dispatch = useDispatch();
	const {loading, announcements } = useSelector(s => s.announcements);

	useEffect(() => {
		if (!loading) return;
		dispatch(getProviderAnnouncementsFor());
	}, []);

	return announcements;
};

export { useAnnouncements };