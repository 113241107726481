import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Notification} from "library/dashboard/notification";
import api from "library/api";

const getNotifications = createAsyncThunk("notifications/get", async() => {
	return await api.Notifications.get();
});

interface INotifications {
	loading: boolean;
	notifications: Notification[] | undefined
}

const initialState: INotifications = {
	loading: false,
	notifications: undefined
};

const notifications = createSlice(({
	name: "notificationsState",
	initialState,
	reducers :{},
	extraReducers: builder => {
		builder.addCase(getNotifications.pending, (state) => ({...state, loading: true}));
		builder.addCase(getNotifications.fulfilled, (state, {payload}) => ({...state, loading: false, notifications: payload}));
	}
}));

export default notifications.reducer;

export {getNotifications};
