// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import { dispatch } from "../index";

// types
import { DefaultRootStateProps } from "types";

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps["calendar"] = {
	error: null,
	events: []
};

const slice = createSlice({
	name: "calendar",
	initialState,
	reducers: {
		// HAS ERROR
		hasError(state, action) {
			state.error = action.payload;
		},

		// GET EVENTS
		getEventsSuccess(state, action) {
			state.events = action.payload;
		},

		// ADD EVENT
		addEventSuccess(state, action) {
			state.events = action.payload;
		},

		// UPDATE EVENT
		updateEventSuccess(state, action) {
			state.events = action.payload;
		},

		// REMOVE EVENT
		removeEventSuccess(state, action) {
			state.events = action.payload;
		}
	}
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEvents() {
	return async () => {
		try {
			const response = await axios.get("/api/calendar/events");
			dispatch(slice.actions.getEventsSuccess(response.data.events));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function addEvent(event: {[field: string]: any}) {
	return async () => {
		try {
			const response = await axios.post("/api/calendar/events/new", event);
			dispatch(slice.actions.addEventSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function updateEvent(event: {[field: string]: any}) {
	return async () => {
		try {
			const response = await axios.post("/api/calendar/events/update", event);
			dispatch(slice.actions.updateEventSuccess(response.data.events));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function removeEvent(eventId: string) {
	return async () => {
		try {
			const response = await axios.post("/api/calendar/events/remove", { eventId });
			dispatch(slice.actions.removeEventSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
