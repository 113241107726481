class ClientRegistrationResult {
	readonly isNewUser: boolean;
	readonly isExistingUser: boolean;
	readonly clientCode: string;
	readonly hasError: boolean;
	readonly accountNotFound: boolean;

	constructor (from?: Partial<ClientRegistrationResult>) {
		this.isExistingUser = from?.isExistingUser ?? false;
		this.isNewUser = from?.isNewUser ?? false;
		this.clientCode = from?.clientCode ?? "";
		this.hasError = from?.hasError ?? false;
		this.accountNotFound = !this.isNewUser && !this.isExistingUser && !this.hasError;
	}
}
export {ClientRegistrationResult};
