// material-ui
import {styled} from "@mui/material/styles";

// project imports
import AppBar from "ui-component/extended/AppBar";

// assets
import headerBackground from "assets/images/landing/screeningBands.jpg";
import RegistrationContainer from "./RegistrationContainer";

const HeaderWrapper = styled("div")(({ theme }) => ({
	backgroundImage: `url(${headerBackground})`,
	backgroundSize: "100% 600px",
	backgroundAttachment: "fixed",
	backgroundRepeat: "no-repeat",
	textAlign: "center",
	paddingTop: 30,
	[theme.breakpoints.down("md")]: {
		paddingTop: 0
	}
}));

const Registration = () => {
	return (
		<HeaderWrapper>
			<AppBar />
			<RegistrationContainer />
		</HeaderWrapper>
	);
};

export default Registration;
