import {createErrorState, ErrorState, IErrorState} from "../errorState";

interface IApplicationConsent {
	contactForResearch: boolean;
	authorizeCommunication: boolean;
	/** Frontend only */
	understandResponsibilities: boolean;
}


class ApplicationConsent implements Partial<IApplicationConsent>, IErrorState<Partial<IApplicationConsent>> {
	[key: string]: unknown;
	
	readonly contactForResearch?: boolean;
	readonly authorizeCommunication?: boolean;
	readonly understandResponsibilities?: boolean;
	private readonly validated: boolean;
	
	constructor(from?: Partial<ApplicationConsent>) {
		this.contactForResearch = from?.contactForResearch ?? false;
		this.authorizeCommunication = from?.authorizeCommunication ?? false;
		this.understandResponsibilities = from?.understandResponsibilities ?? false;	
		this.validated = (from as ApplicationConsent)?.validated ?? false;			
	}
	
	get errorState(): ErrorState<Partial<IApplicationConsent>> {
		return this.validated ?
			createErrorState<IApplicationConsent>({
			}) : {};
	}
	
	validate(): ApplicationConsent {
		return new ApplicationConsent({...this, validated: true});
	}
}

export { ApplicationConsent};
export type { IApplicationConsent};