import styles from "./confirmationBox.module.scss";

import { FC } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SectionHeading } from "../SectionHeading";


const SubmissionConfirmationBox: FC<{ confirmation: string, title: string }> = ({ confirmation, title}) => {
	const theme = useTheme();

	return (
		<Box sx={{ borderColor: theme.palette.primary.main }} className={styles.confirmationBox}>
			<Box sx={{ color: theme.palette.background.paper }} className={styles.confirmationBoxHeadingText}>
				{`${title}`}
			</Box>
			<SectionHeading>{confirmation}</SectionHeading>
		</Box>
	);
};

export { SubmissionConfirmationBox };