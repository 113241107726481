// auth provider
import {
	ContextHolder,
	useAuth as useFronteggAuth,
	useLoginWithRedirect
} from "@frontegg/react";
import {AuthenticationContextType} from "../types/auth";
import {useCallback} from "react";
import {useParams} from "react-router-dom";

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = (): AuthenticationContextType => {
	const {user, isAuthenticated} = useFronteggAuth();
	const firstName = user?.name?.split("(")[0] ?? "";
	const loginWithRedirect = useLoginWithRedirect();
	const {tenant} = useParams();

	const logout = useCallback(() => {
		const baseUrl = ContextHolder.getContext().baseUrl;
		window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}/${tenant}`;
	}, []);

	return {
		user,
		firstName,
		isInitialized: true,
		isLoggedIn: isAuthenticated,
		login: loginWithRedirect,
		logout
	};
};
export default useAuth;