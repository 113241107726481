import { createErrorState, IErrorState } from "../errorState";
import { Dependant } from "../intake";
import { Adult } from "./adult";

interface IHousehold {
	adults: Adult[],
	dependants: Dependant[]
}

class Household implements Partial<IHousehold>, IErrorState<Partial<IHousehold>> {
	readonly adults: Adult[] = [];
	readonly dependants: Dependant[] = [];
	private readonly validated: boolean = false;

	constructor(from?: Partial<IHousehold>) {
		if (!from) return;

		this.adults = (!("members" in from)
			? from.adults ?? []
			: (from.members as [])?.filter(m => !(m as Dependant).isDependant) ?? [])
			.map(a => new Adult(a));


		this.dependants = !("members" in from)
			? from.dependants ?? []
			: (from.members as [])
				?.filter(m => (m as Dependant).isDependant)
				.map(m => new Dependant(m)) ?? [];

		this.validated = (from as Household)?.validated ?? false;
	}

	validate(): Household {
		return new Household({ ...this, validated: true });
	}

	get errorState() {
		return this.validated ? createErrorState({}) : {};
	}

	toJSON() {
		return {
			members: [...this.adults, ...this.dependants],
			validated: this.validated
		};
	}
}

export { Household };
export type { IHousehold };