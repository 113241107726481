import {createSlice} from "@reduxjs/toolkit";

const addressLookup = createSlice(({
	name: "addressLookupState",
	initialState: {cssLoaded: false, scriptLoaded: false},
	reducers: {
		setCssLoaded: (state) => ({...state, cssLoaded: true}),
		setScriptLoaded: (state) => ({...state, scriptLoaded: true})
	}
}));

export default addressLookup.reducer;
export const { setCssLoaded, setScriptLoaded } = addressLookup.actions;