import { FormContainer } from "ui-component/extended/FormContainer";
import { Container } from "@mui/system";

import {ApplicationHeaderInfo, FormHeaderTextGroup} from "components/FormHeaderTextGroup";
import MigratingClientRegistration from "./MigratingClientRegistration";
import {PROVIDER_EMAIL, PROVIDER_PHONE} from "config";


const headerInfo: ApplicationHeaderInfo = {
	title: "Create Account",
	subtitle: "Let's create and link your previous account",
	message: `Please create an account with your email address from the previous system. We will use this to lookup your history. Contact our Rent Supplement team at ${PROVIDER_EMAIL} or ${PROVIDER_PHONE} if you have any questions or concerns. `
};

const RegistrationContainer = () => {
	return (
		<Container sx={{ height: "100vh" }}>
			<FormHeaderTextGroup headerInfo={headerInfo} />
			<FormContainer>
				<MigratingClientRegistration />
			</FormContainer>
		</Container>
	);
};

export default RegistrationContainer;
