import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "library/api";
import {Announcement} from "library/dashboard/announcement";


const getProviderAnnouncementsFor = createAsyncThunk("announcements/get", async() => {
	return await api.Announcements.get();
});

interface IAnnouncements {
	loading: boolean;
	announcements: Announcement[]
}

const initialState: IAnnouncements = {
	loading: true,
	announcements: [] as Announcement[]
};

const announcements = createSlice(({
	name: "announcementsState",
	initialState,
	reducers :{},
	extraReducers: builder => {
		builder.addCase(getProviderAnnouncementsFor.pending, (state) => ({...state, loading: true}));
		builder.addCase(getProviderAnnouncementsFor.fulfilled, (state, {payload}) => ({...state, loading: false, announcements: payload}));
	}
}));

export default announcements.reducer;

export {getProviderAnnouncementsFor};