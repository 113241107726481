import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Program} from "types/program";
import api from "library/api";

const getPrograms = createAsyncThunk("programs/get", async () => {
	return await api.Programs.list();
});

interface IPrograms {
	loading: boolean;
	programs: Program[] | undefined
}

const initialState: IPrograms = {
	loading: false,
	programs: undefined
};

const programs = createSlice(({
	name: "programsState",
	initialState,
	reducers :{},
	extraReducers: builder => {
		builder.addCase(getPrograms.pending, (state) => ({...state, loading: true}));
		builder.addCase(getPrograms.fulfilled, (state, {payload}) => ({...state, loading: false, programs: payload}));
	}
}));

export default programs.reducer;

export {getPrograms};