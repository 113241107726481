import axios, {AxiosResponse} from "axios";
import {Application, ApplicationBuilder, ApplicationDocuments, IAccountInfo, Submission} from "./application";
import {Announcement} from "./dashboard/announcement";
import {User} from "@frontegg/redux-store";
import {Program} from "types/program";
import {Notification} from "./dashboard/notification";
import {IntakeBuilder} from "./intake";
import {IncomeType} from "./incomeTypes";
import {HousingBody} from "./civida/managedCities";
import {ClientRegistrationResult} from "./ClientRegistrationResult";

const HOST = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = false;

enum ApiRoutes {
	Screening = "screening/",
	Application = "application/",
	HousingBodies = "housingBodies/",
	IncomeTypes = "incomeTypes/",
	Notifications = "notifications/",
	Announcements = "announcements/",
	TenantUser = "tenantuser/",
	AnnualReview = "annualReview/",
	InterimReview = "interimReview/",
	Auth = "auth/"
}

axios.defaults.baseURL = HOST;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
	get: <T>(url: string, params?: URLSearchParams) => axios.get<T>(url, {params}).then(responseBody),
	post: <T>(url: string, body: unknown) => axios.post<T>(url, body).then(responseBody),
	put: <T>(url: string, body: unknown) => axios.put<T>(url, body).then(responseBody),
	delete: (url: string) => axios.delete(url).then(responseBody),
	postFile: (url: string, file: File) => axios.post(url, file, {headers: {"Content-type": file.type}}).then(responseBody),
};

const Screening = {
	submit: (value: IntakeBuilder) => requests
		.post<IntakeBuilder>(`${HOST}${ApiRoutes.Screening}`, value)
		.then(r => new IntakeBuilder(r))
};

const Applications = {
	create: (application: ApplicationBuilder) => requests
		.post<ApplicationBuilder>(`${HOST}${ApiRoutes.Application}`, application)
		.then(r => new ApplicationBuilder(r)),
	get: (code: string) => requests
		.get<ApplicationBuilder>(`${HOST}${ApiRoutes.Application}${code}`)
		.then(r => new ApplicationBuilder(r)),
	update: (application: ApplicationBuilder) => requests
		.put<ApplicationBuilder>(`${HOST}${ApiRoutes.Application}`, application)
		.then(r => new ApplicationBuilder(r)),
	submit: (application: Application) => requests
		.put<Submission>(`${HOST}${ApiRoutes.Application}`, application)
		.then(r => new Submission(r)),
	addFile: (applicationId: string, type: keyof ApplicationDocuments, file: File) => requests
		.postFile(`application/${applicationId}/documents/${type}/${file.name}`, file),
	submitDocuments: (applicationId: string, bankInfo: IAccountInfo) => requests
		.put(`application/${applicationId}/documents`, bankInfo)
};

const IncomeTypes = {
	get: () => requests
		.get<{ items: IncomeType[], metaData: { totalCount: number } }>(`${HOST}${ApiRoutes.IncomeTypes}`)
		.then(r => r.items)
};

const HousingBodies = {
	get: () => requests
		.get<HousingBody[]>(`${HOST}${ApiRoutes.HousingBodies}`)
		.then()
};

const Announcements = {
	get: () => requests
		.get<{items: Announcement[]}>(`${HOST}${ApiRoutes.Announcements}`)
		.then(a => a.items.map(i => new Announcement(i)))
};

const Users = {
	set: (user: User | null | undefined) => {
		if (user)
			axios.defaults.headers.common = {"Authorization": `Bearer ${user.accessToken}`};
		else
			delete axios.defaults.headers.common.Authorization;
	}
};

const Programs = {
	list: () => requests
		.get<Program[]>(`${HOST}${ApiRoutes.TenantUser}Programs`)
		.then(r => r.map(program => new Program(program)))
};

const InterimReviews = {
	create: () => requests
		.post<ApplicationBuilder>(`${HOST}${ApiRoutes.InterimReview}`, {})
		.then()
};

const AnnualReviews = {
	create: () => requests
		.post<ApplicationBuilder>(`${HOST}${ApiRoutes.AnnualReview}`, {})
		.then(r => new Application(r))
};

const Notifications = {
	get: () => requests
		.get<Notification[]>(`${HOST}${ApiRoutes.TenantUser}${ApiRoutes.Notifications}`)
		.then(result => result.map(n => new Notification(n)))
};

const Register = {
	create: (address:string) => requests
		.post<ClientRegistrationResult>(`${HOST}${ApiRoutes.Auth}register`,{address})
		.then(r => new ClientRegistrationResult(r))
};
export default {
	Screening,
	Applications, 
	IncomeTypes,
	HousingBodies,
	Announcements,
	Users,
	Programs,
	InterimReviews,
	AnnualReviews,
	Notifications,
	Register
};