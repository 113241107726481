import {AlertColor} from "@mui/material";

enum AnnouncementType {
	Site = "Site",
	Info = "Info",
	Warning = "Warning",
	Direct = "Direct",
	Other = "Other"

}

interface IAnnouncement {
	title: string;
	message: string;
	type: AnnouncementType;
	style: AlertColor;
}

const AnnouncementStyle: Record<AnnouncementType, AlertColor> = {
	Site: "error",
	Info: "info",
	Warning: "warning",
	Direct: "success",
	Other: "info"
};

class Announcement implements IAnnouncement {
	title: string;
	message: string;
	type: AnnouncementType;
	style: AlertColor;

	constructor(from?: Partial<IAnnouncement>) {
		this.type = from?.type ?? AnnouncementType.Other;
		this.style = AnnouncementStyle[this.type];
		this.message = from?.message ?? "";
		this.title = from?.title ?? "";
	}
}

export type {IAnnouncement};
export {Announcement, AnnouncementType};