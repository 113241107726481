import { YesNo } from "library/enums";
import { createErrorState, ErrorState, IErrorState } from "../errorState";
import { NoticeOfAssessmentIncome, OtherIncome } from "./incomeBase";

interface IIncomeAssets {
	noticeOfAssessment: YesNo | undefined;
	otherIncome: NoticeOfAssessmentIncome[];
	noticeOfAssessmentIncome: OtherIncome[];
	noIncomeDeclaration: boolean;
}

class IncomeAssets implements Partial<IIncomeAssets>, IErrorState<Partial<IIncomeAssets>> {
	[key: string]: unknown;
	
	readonly noticeOfAssessment: YesNo | undefined;
	readonly noticeOfAssessmentIncome: NoticeOfAssessmentIncome[] = [];
	readonly otherIncome: OtherIncome[] = [];
	readonly noIncomeDeclaration: boolean = false;
	private readonly validated: boolean = false;


	constructor(from?: Partial<IIncomeAssets>) {
		this.noticeOfAssessment = from?.noticeOfAssessment;
		this.noticeOfAssessmentIncome = from?.noticeOfAssessmentIncome?.map(i => new NoticeOfAssessmentIncome(i)) ?? [];
		this.otherIncome = from?.otherIncome?.map(i => new OtherIncome(i)) ?? [];
		this.validated = (from as IncomeAssets)?.validated ?? false;
		this.noIncomeDeclaration = from?.noIncomeDeclaration ?? false;
	}

	get errorState(): ErrorState<Partial<IIncomeAssets>> {
		return this.validated ?
			createErrorState<IIncomeAssets>({
				noticeOfAssessment: !this.noticeOfAssessment ? "Selection required" : "",
				noticeOfAssessmentIncome: (this.noticeOfAssessment === YesNo.Yes) && this.noticeOfAssessmentIncome.length == 0 ? "Notice of assessment income required" : "",				
			}) : {};
	}

	get includeSocialProgramSource(): boolean {
		return [...this.noticeOfAssessmentIncome, ...this.otherIncome].some(i => i.source?.isSocialProgram);
	}

	validate(): IncomeAssets {
		return new IncomeAssets({ ...this, validated: true });
	}
}

export { IncomeAssets };
export type { IIncomeAssets };