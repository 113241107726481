import React, {cloneElement, useState, ReactElement} from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
	AppBar as MuiAppBar,
	Box,
	Button,
	Container,
	Drawer,
	IconButton,
	Link,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText, Menu, MenuItem,
	Stack,
	Toolbar,
	Typography,
	useScrollTrigger
} from "@mui/material";

// project imports
import Logo from "ui-component/Logo";

// assets
import {IconForms, IconInfoCircle, IconHome2, IconKey} from "@tabler/icons";
import MenuIcon from "@mui/icons-material/Menu";
import useAuth from "hooks/useAuth";
import {AccountCircle} from "@mui/icons-material";

// elevation scroll
interface ElevationScrollProps {
	children: ReactElement;
	window?: Window | Node;
}

function ElevationScroll({ children, window }: ElevationScrollProps) {
	const theme = useTheme();
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window!
	});

	return cloneElement(children, {
		elevation: trigger ? 1 : 0,
		style: {
			backgroundColor: theme.palette.mode === "dark" && trigger ? theme.palette.dark[800] : theme.palette.background.default,
			color: theme.palette.text.dark
		}
	});
}

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

function AppBar ({ ...others }) {
	const [drawerToggle, setDrawerToggle] = useState<boolean>(false);
	const {isLoggedIn, logout, login, firstName} = useAuth();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const {tenant} = useParams();

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const drawerToggler = (open: boolean) => (event: any) => {
		if (event.type! === "keydown" && (event.key! === "Tab" || event.key! === "Shift")) {
			return;
		}
		setDrawerToggle(open);
	};
	return (
		<ElevationScroll {...others}>
			<MuiAppBar sx={{position: "unset"}}>
				<Container>
					<Toolbar sx={{ py: 2.5, px: "0 !important" }}>
						<Typography component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
							<Logo />
						</Typography>
						<Stack direction="row" sx={{
							display: { xs: "none", sm: "flex"},
							spacing: { xs: 1.5, md: 2.5 },
							"& .MuiButton-root": { // This targets all Button components inside the Stack
								fontSize: "1.25rem", // set all the menu items the same size
							}
						}}>
							<Button color="inherit" component={RouterLink} to={isLoggedIn ? `/${tenant}/pages/dashboard` : `/${tenant}/pages/landing`} >
								Home
							</Button>
							<Button color="inherit" component={RouterLink} to={`/${tenant}/pages/intake`}>
								Apply
							</Button>
							<Button color="inherit" component="a" href="https://civida.ca/rent-assistance/rent-assistance-faqs/" target="_blank">
								FAQ
							</Button>
							<Button color="inherit" component={RouterLink} to={`/${tenant}/pages/privacy-policy`} target={"_blank"}>
								Privacy Policy
							</Button>
							{isLoggedIn 
								? (
									<div style={{display: "flex", paddingLeft: "1rem", alignContent: "center", justifyContent: "center", alignItems:"center"}}>
										<div>
											<IconButton onClick={handleClick}>
												<AccountCircle sx={{height: "30px"}} color={"info"}/>
											</IconButton>
											<Menu
												id="userMenu"
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
											>
												<MenuItem onClick={logout}>Logout</MenuItem>
											</Menu>
										</div>
										<div style={{display: "flex", alignItems: "center"}}>
											<Typography variant={"h3"}>{firstName}</Typography>
										</div>
									</div>
								)
								: (
									<Button color="inherit" onClick={() => login()}>
										Login
									</Button>
								)
							}
						</Stack>
						<Box sx={{ display: { xs: "block", sm: "none" } }}>
							<IconButton color="inherit" onClick={drawerToggler(true)} size="large">
								<MenuIcon />
							</IconButton>
							<Drawer anchor="top" open={drawerToggle} onClose={drawerToggler(false)}>
								{drawerToggle && (
									<Box
										sx={{ width: "auto" }}
										role="presentation"
										onClick={drawerToggler(false)}
										onKeyDown={drawerToggler(false)}
									>
										<List>
											<Link style={{ textDecoration: "none" }} href={isLoggedIn ? `/${tenant}/pages/dashboard` : `/${tenant}/pages/landing`}>
												<ListItemButton>
													<ListItemIcon>
														<IconHome2 />
													</ListItemIcon>
													<ListItemText primary="Home" />
												</ListItemButton>
											</Link>
											<Link style={{ textDecoration: "none" }} href={`/${tenant}/pages/intake`}>
												<ListItemButton>
													<ListItemIcon>
														<IconForms />
													</ListItemIcon>
													<ListItemText primary="Apply" />
												</ListItemButton>
											</Link>
											<Link style={{ textDecoration: "none" }} href={"https://civida.ca/rent-assistance/faqs/"} target={"_blank"}>
												<ListItemButton>
													<ListItemIcon>
														<IconInfoCircle />
													</ListItemIcon>
													<ListItemText primary="FAQ" />
												</ListItemButton>
											</Link>
											<Link style={{ textDecoration: "none" }} href={`/${tenant}/pages/privacy-policy`}>
												<ListItemButton>
													<ListItemIcon>
														<IconInfoCircle />
													</ListItemIcon>
													<ListItemText primary="Privacy Policy" />
												</ListItemButton>
											</Link>
											<Link>
												<ListItemButton onClick={() => isLoggedIn ? logout() : login()}>
													<ListItemIcon>
														<IconKey />
													</ListItemIcon>
													{ isLoggedIn ?
														<ListItemText primary="Logout" /> : <ListItemText primary="Login" />
													}
												</ListItemButton>
											</Link>
										</List>
									</Box>
								)}
							</Drawer>
						</Box>
					</Toolbar>
				</Container>
			</MuiAppBar>
		</ElevationScroll>
	);

}

export default AppBar;