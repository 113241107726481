import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "library/api";
import {ApplicationBuilder} from "library";

const createInterimReview = createAsyncThunk("interimReviews/create",	async () => {
	const review = await api.InterimReviews.create();
	if (!review) throw Error("Failed to create interim review");
	
	return review;
});

const interimReview = createSlice({
	name: "annualReviewsState",
	initialState: {loading: false, value: new ApplicationBuilder()},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createInterimReview.pending, (state) =>
			({ value: state.value, loading: true }));
		builder.addCase(createInterimReview.fulfilled, (_, {payload}) =>
			({ value: payload, loading: false }));
		builder.addCase(createInterimReview.rejected, (state) =>
			({ value: state.value, loading: false }));
	}
});

export default interimReview.reducer;
export { createInterimReview };
