import {IncomeType} from "library";
import {createSlice} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "./appAsyncThunk";
import api from "library/api";

const getIncomeTypes = createAppAsyncThunk("incomeTypes/load", async (_: void, {getState}) => {
	const {incomeTypes} = getState();
	if (!incomeTypes.loading) return incomeTypes.value;
	
	return await api.IncomeTypes.get();
});

const incomeTypes = createSlice({
	name: "incomeTypesState",
	initialState: {loading: true, value: [] as IncomeType[]},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getIncomeTypes.pending, (state) => ({...state, loading: true}));
		builder.addCase(getIncomeTypes.fulfilled, (state, {payload}) => ({loading: false, value: payload}));
	}
});

export default incomeTypes.reducer;

export {getIncomeTypes};