// project imports
import MinimalLayout from "layout/MinimalLayout";
import {lazy} from "react";
import Loadable from "ui-component/Loadable";
import Registration from "../views/pages/registration";

// ==============================|| MAIN ROUTING ||============================== //
// maintenance routing
const MaintenanceError = Loadable(lazy(() => import("views/pages/maintenance/Error")));
const MaintenanceUnderConstruction = Loadable(lazy(() => import("views/pages/maintenance/UnderConstruction")));

// landing + application / intake routing
const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));
const PagesIntake = Loadable(lazy(() => import("views/pages/intake")));
const PagesFaqs = Loadable(lazy(() => import("views/pages/misc/Faqs")));
const PagesPrivacyPolicy = Loadable(lazy(() => import("views/pages/misc/PrivacyPolicy")));

const MainRoutes = {
	path: "/:tenant/*",
	element: (
		<MinimalLayout />
	),
	children: [
		{
			path: "pages/error",
			element: <MaintenanceError />
		},
		{
			path: "pages/under-construction",
			element: <MaintenanceUnderConstruction />
		},
		{
			path: "pages/landing",
			element: <PagesLanding />
		},
		{
			path: "pages/intake/*",
			element: <PagesIntake />
		},
		{
			path: "pages/faqs",
			element: <PagesFaqs />
		},
		{
			path: "pages/privacy-policy",
			element: <PagesPrivacyPolicy />
		},
		{
			path: "pages/registration",
			element: <Registration />
		}

	]
};

export default MainRoutes;
