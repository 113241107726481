import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AuthenticatedLayout from "layout/AuthenticatedLayout";

// maintenance routing

// landing + application / intake routing
const PagesApplication = Loadable(lazy(() => import("views/pages/application")));
const PagesDashboard = Loadable(lazy(() => import("views/pages/dashboard")));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
	path: "/:tenant/*",
	element: <AuthenticatedLayout />,
	children: [
		{
			path: "pages/application/*",
			element: <PagesApplication />
		},
		{
			path: "pages/dashboard",
			element: <PagesDashboard />
		},
	]
};

export default AuthenticationRoutes;