class IncomeType {
	readonly id?: string;
	readonly code: string;
	readonly name: string;
	readonly excluded: boolean;
	readonly isSocialProgram: boolean;
	readonly status: "Active" | "Inactive";

	constructor(from?: Partial<IncomeType>) {
		this.id = from?.id;
		this.code = from?.code ?? "";
		this.name = from?.name ?? "";
		this.excluded = from?.excluded ?? false;
		this.isSocialProgram = from?.isSocialProgram ?? false;
		this.status = from?.status ?? "Active";
	}
}

export {
	IncomeType
};