import {Alert, AlertTitle, Stack} from "@mui/material";
import {useAnnouncements} from "hooks/useAnnouncements";
import {AnnouncementType} from "../library/dashboard/announcement";

const SiteBanner = () => {
	const announcements = useAnnouncements();

	return(
		<>
			<Stack spacing={1}>
				{announcements?.filter(a => a.type === AnnouncementType.Site)
					.map((announcement) => (
						<Alert
							severity={"error"}
							variant={"filled"}
							key={announcement.message}>
							<AlertTitle>{announcement.title}</AlertTitle>
							{announcement.message}
						</Alert>))}
			</Stack>
		</>
	);

};
export default SiteBanner;