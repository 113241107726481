import {DateValue} from "library/DateValue";
import {ClientStatus} from "library";

export class Program {
	readonly id: string;
	readonly name: string;
	readonly description: string;
	readonly startDate: DateValue;
	readonly endDate: DateValue;
	readonly nextPayment: {paymentDate: DateValue, amount: number} | undefined;
	readonly action?: ProgramAction;
	readonly clientStatus: ClientStatus;
	readonly clientStatusChangeDate: DateValue;

	constructor(from?: Partial<Program>) {
		this.id = from?.id ?? crypto.randomUUID();
		this.name = from?.name ?? "";
		this.description = from?.description ?? "";
		this.startDate = new DateValue(from?.startDate);
		this.endDate = new DateValue(from?.endDate);
		this.nextPayment = from?.nextPayment ? {paymentDate: new DateValue(from?.nextPayment?.paymentDate), amount: from?.nextPayment?.amount ?? 0} : undefined;
		this.action = from?.action;
		this.clientStatus = from?.clientStatus ?? ClientStatus.Inactive;
		this.clientStatusChangeDate = new DateValue(from?.clientStatusChangeDate);
	}
}

export enum ProgramAction {
	None,
	BeginInterimReview,
	BeginAnnualReview
}