import { ErrorState, IErrorState } from "../errorState";

type IProgramApplications = {
	programs: Set<string> | undefined,
}

class ProgramApplications implements Partial<IProgramApplications>, IErrorState<Partial<IProgramApplications>> {
	readonly programs?: Set<string>;
	
	constructor(from?: Partial<ProgramApplications> | string[]) {
		const programArray = from instanceof Array ? from : (from?.programs ? [...from.programs] : []); 
		this.programs = new Set(programArray.length ? programArray : ["RAB"]);
	}
	
	get errorState(): ErrorState<Partial<IProgramApplications>> {
		return {};
	}
	
	validate(): ProgramApplications {
		return this;
	}

	toJSON() {
		return Array.from(this.programs!.values());
	}
}

export { ProgramApplications };
export type { IProgramApplications };