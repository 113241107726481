import { Application, ApplicationBuilder } from "./application";
import { ErrorState, IErrorState } from "./errorState";

class AnnualReview implements IErrorState<Partial<AnnualReview>> {
	readonly clientId?: string;
	readonly application?: Application;
	readonly dateDue?: string;

	constructor(from?: Partial<AnnualReview>) {
		this.clientId = from?.clientId;
		this.application = from?.application
			? new ApplicationBuilder(from.application).build()
			: undefined;
		this.dateDue = from?.dateDue;
	}

	get errorState(): ErrorState<Partial<AnnualReview>> {
		return {};
	}
}

export { AnnualReview };
