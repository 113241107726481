import {IPerson} from "./person";
import {IScreeningInformation} from "./screeningInformation";
import {CitizenStatus, Gender, LivingSituation, YesNo} from "../enums";
import {ApplicationBuilder, ApplicantInformation} from "../application";
import {Address, Email} from "../types";

class Intake implements IPerson, IScreeningInformation {
	[key: string]: unknown;

	readonly firstName: string = "";
	readonly middleName: string = "";
	readonly lastName: string = "";
	readonly preferredName: string = "";
	readonly dateOfBirth: Date = new Date();
	readonly gender: Gender = Gender.NonBinary;
	readonly address: Address = new Address();
	readonly citizenship: CitizenStatus = CitizenStatus.Other;
	readonly assetsAboveThreshold: YesNo = YesNo.No;
	readonly householdIncome: number = 0;
	readonly livingSituation: LivingSituation = LivingSituation.Temporary;
	readonly signedLease: YesNo = YesNo.Yes;
	readonly displayName = "";
	readonly age: number = 0;
	readonly email: Email = {};
	readonly userId: string = "";

	constructor(readonly id: string, readonly isNewUser:boolean, from: Partial<IPerson> & Partial<IScreeningInformation>) {
		Object.entries(from).forEach(([k, v]) => this[k] = v);
	}

	createApplication(userId: string): ApplicationBuilder {
		//City required for screening but not required for application and should be removed in order to allow address entry and lookup at the applicant stage.
		return new ApplicationBuilder( {userId: userId, applicant: new ApplicantInformation({...this, address: undefined}), screeningId: this.id});
	}
}

export { Intake };