import {createErrorState, ErrorState, IErrorState} from "../errorState";
import {Address, Email, Phone} from "../types";
import {IncomeAssets} from "../application";
import { HouseholdMember } from "library/intake";

class Adult extends HouseholdMember implements IErrorState<Partial<Adult>> {
	readonly addressSameAsApplicant: boolean;
	readonly mailingAddressSameAsAddress: boolean;
	readonly mailingAddress?: Address;
	readonly primaryPhone?: Phone;
	readonly secondaryPhone?: Phone;
	readonly email?: Email;
	readonly income?: IncomeAssets;
	
	constructor(from?: Partial<Adult>) {
		super(from);
		
		this.addressSameAsApplicant = from?.addressSameAsApplicant ?? false;
		this.mailingAddressSameAsAddress = from?.mailingAddressSameAsAddress ?? false;
		this.mailingAddress = from?.mailingAddressSameAsAddress 
			? this.address 
			: from?.mailingAddress instanceof Address ? from.mailingAddress : new Address(from?.mailingAddress);
		this.primaryPhone = new Phone(from?.primaryPhone);
		this.secondaryPhone = new Phone(from?.secondaryPhone);
		this.email = from?.email;
		this.income = new IncomeAssets(from?.income);
	}
	
	validate(): Adult {
		return new Adult({...this, ...super.validate()});
	} 
	
	get errorState(): ErrorState<Partial<Adult>> {
		const superErrorState = super.validate().errorState;
		return this.validated ? createErrorState<Adult>({
			...superErrorState, 
			address: !this.address?.isComplete && !this.addressSameAsApplicant ? "Required" : "", 
			mailingAddress: !this.mailingAddress?.isComplete && !this.mailingAddressSameAsAddress ? "Required" : ""
		}) : {};
	}
}

export {Adult};