import { NotificationAction } from "library/enums";

interface INotification {
	code: string;
	title: string;
	description: string;
	infoText: string;
	alertText: string;
	alertDate: Date;
	instructions: string;
	action: NotificationAction
}

class Notification implements INotification {
	code: string;
	title: string;
	description: string;
	infoText: string;
	alertText: string;
	instructions: string;
	action: NotificationAction;
	alertDate: Date;

	constructor(from?: Partial<INotification>) {
		this.code = from?.code ?? "";
		this.title = from?.title ?? "";
		this.description = from?.description ?? "";
		this.infoText = from?.infoText ?? "";
		this.alertText = from?.alertText ?? "";
		this.instructions = from?.instructions ?? "";
		this.action = from?.action ?? NotificationAction.NoAction;
		this.alertDate = from?.alertDate ?? new Date();
	}
}

export {Notification};
export type {INotification};
