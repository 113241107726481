import LAYOUT_CONST from "constant";

// types
import { ConfigProps } from "types/config";

export const PROVIDER_NAME = "Civida";
export const PROVIDER_PHONE = "780-420-6161";
export const PROVIDER_EMAIL = "benefits@civida.ca";
export const PROVIDER_FOIP_EMAIL = "FOIPcoordinator@civida.ca";
export const PROVIDER_FOIP_PHONE = "780-420-6161 ex 457";
export const PROVIDER_DOMAIN = "CIVIDA.CA";
// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = "";

export const HORIZONTAL_MAX_ITEM = 6;

// export const CP_PUB_KEY = "DN49-GW34-YK82-EP78"; // Kingston's key
export const CP_PUB_KEY = "TW99-CN65-NK87-AC65"; // ppulus' key

const config: ConfigProps = {
	layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
	drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
	fontFamily: "'Roboto', sans-serif",
	borderRadius: 8,
	outlinedFilled: true,
	navType: "light", // light, dark
	presetColor: "provider", // default, theme1, theme2, theme3, theme4, theme5, theme6
	locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
	rtlLayout: false,
	container: false,

};

export default config;
