interface ISubmission {
	code: string;
	waitTimeInDays: number;
	pointScore: {total: number};
	confirmation: string;
}

class Submission implements Partial<ISubmission> {
	readonly code: string;
	readonly waitTimeInDays: number;
	readonly pointScore: { total: number };
	readonly confirmation: string;
	
	constructor(from: ISubmission) {
		this.code = from?.code;
		this.waitTimeInDays = from?.waitTimeInDays ?? 0;
		this.pointScore = from?.pointScore ?? {total: 0};
		this.confirmation = from?.confirmation;
	}
}

export { Submission };
export type { ISubmission };