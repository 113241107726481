import dayjs, {Dayjs} from "dayjs";

class DateValue {
	public readonly value: Dayjs;

	constructor(date: DateValue | Date | string | number | undefined | null | dayjs.Dayjs) {
		if (date instanceof DateValue ) {
			this.value = date.value;
			return;
		}

		if (!date || !dayjs(date).isValid) {
			this.value = dayjs(new Date());
			return;
		}

		this.value = dayjs(date);
	}

	public static isDate(value: any): boolean {
		if (!value) return false;
		if (value instanceof Date || value instanceof DateValue) return true;
		return dayjs(value).isValid();
	}

	public static create(value?: DateValue | Date | string): DateValue | undefined {
		if (!value || value === "0001-01-01") return;
		if (value instanceof DateValue) return value;
		return new DateValue(value);
	}

	public static isDateMin(value: Date): boolean {
		return new Date(value).getUTCFullYear() == 1;
	}

	format(format: string = "YYYY-MM-DD"): string {
		return this.value.format(format);
	}

	addYears(years: number) {
		return this.value.add(years,"y");
	}

	public static From(value: any) {
		return value && DateValue.isDate(value) ? new DateValue(value) : undefined;
	}
	
	get isValid() {
		return this.value.isValid();
	}
	
	toDate(): Date {
		return this.value.toDate();
	}
	
	toJSON(): string {
		return this.value.toDate().toISOString();
	}
}

export { DateValue } ;