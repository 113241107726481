import {isEmpty} from "./common";

type ErrorState<T> = {
	[property in keyof Omit<T, "errorState">]: string;
}

interface IErrorState<T> {
	errorState?: ErrorState<T>
}

const createErrorState = <T extends object>(value: Partial<Record<keyof T, string>>) => Object.fromEntries(Object.entries(value).filter(([,v]) => !!v));

export const hasErrors: (property: any) => boolean = (property: any) => {

	return typeof property === "object" && !isEmpty(property) &&
		((property.errorState && Object.values(property.errorState).some(v => v !== "")) ||
			Object.keys(property)
				.filter(k => k !== "errorState")
				.some(k => hasErrors(property[k])));
};
export {createErrorState};
export type {
	ErrorState, IErrorState
};