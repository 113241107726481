import styles from "styles/screeningResults.module.scss";

import {Button, Grid, Typography} from "@mui/material";
import {GridText} from "components/GridText";
import {fullWidth, halfWidth} from "library";
import {Stack} from "@mui/system";
import React, {useState} from "react";
import {SectionHeading} from "ui-component/SectionHeading";
import {Link as RouterLink, useParams} from "react-router-dom";
import DraftsIcon from "@mui/icons-material/Drafts";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import {registerMigratingUser} from "store/slices/registration";
import {dispatch} from "store";
import {ClientRegistrationResult} from "library/ClientRegistrationResult";
import {PROVIDER_EMAIL, PROVIDER_NAME, PROVIDER_PHONE} from "config";
import useAuth from "hooks/useAuth";
import {SubmissionConfirmationBox} from "ui-component/SubmissionConfirmationBox";

function MigratingClientRegistration() {
	const [email,setEmail] = useState("");
	const [registrationResult,setClientRegistrationResult] = useState<ClientRegistrationResult>();
	const {login} = useAuth();
	const {tenant} = useParams();
	
	const registerClient = async () => 
		await dispatch(registerMigratingUser(email)).unwrap()
			.then(setClientRegistrationResult)
			.catch(setClientRegistrationResult);

	const AccountLinked = () => {
		return (
			<Grid container>
				<Grid item {...fullWidth} textAlign={"center"} sx={{paddingTop: "50px"}}>
					<Typography variant="h2">
						Your previous account was linked in our new system.
					</Typography>
				</Grid>
				{registrationResult?.clientCode &&
					<Grid item container justifyContent={"center"}>
						<Stack {...fullWidth} textAlign={"center"} alignItems={"center"} sx={{margin: "20px"}}>
							<div>
								<SubmissionConfirmationBox title= {"Your recipient code:"} confirmation={registrationResult?.clientCode ?? `CONTACT ${PROVIDER_NAME}`}/>
							</div>
							<Typography variant={"h2"}>Please remember this number for your records.</Typography>
						</Stack>
					</Grid>
				}
				<div className={styles.newUserBanner}>
					<div className={styles.eligibleIcon}>
						<DraftsIcon sx={{fontSize: "60px"}}></DraftsIcon>
					</div>

					<div className={styles.instructionsText}>
						<Typography className={styles.instructionsText}>
							Please check your inbox for an email to verify your email address.
							Click the link in the email to activate your account and begin your Rent Assistance Benefit application.
						</Typography>
					</div>
				</div>
			</Grid>
		);
	};

	const AccountExists = () => {
		return (
			<Grid container>
				<Typography variant="h1" className={styles.existingUserEligibleText}>
						Your email already exists in our system.
				</Typography>
				<Grid item>
					<div className={styles.existingUserBanner} id={"existingUserLoggedIn"}>
						<div className={styles.eligibleIcon}>
							<DoneIcon sx={{fontSize: "60px"}}></DoneIcon>
						</div>
						<Stack className={styles.instructionsText} alignItems={"baseline"}>
							<Typography className={styles.instructionsText}>
								The email address you inputted has been previously registered.
							</Typography>
							<Typography className={styles.instructionsText}>
								Please login by clicking the button below.
							</Typography>
						</Stack>
					</div>
					<Grid item textAlign={"center"} >
						<Button className={styles.existingUserLoginButton} onClick={() => login()} variant={"contained"} >Login</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const AccountNotFound = () => {
		return (
			<Grid container>
				<Typography variant="h3" className={styles.existingUserEligibleText}>
					Email address not found.
				</Typography>
				<Grid item>
					<div className={styles.existingUserBanner} id={"existingUserLoggedIn"}>
						<div className={styles.eligibleIcon}>
							<ErrorIcon sx={{fontSize: "60px"}}></ErrorIcon>
						</div>
						<div className={styles.instructionsText}>
							<Typography className={styles.instructionsText}>
								The email address you inputted is not linked with a legacy or existing account.
								Please contact {PROVIDER_NAME} at {PROVIDER_PHONE} or {PROVIDER_EMAIL} if you have any questions.
								If you want to create a new account, click the Back to Home button and apply.
							</Typography>
						</div>
					</div>
				</Grid>
				<Grid item>

				</Grid>
			</Grid>
		);
	};

	const ErrorResponse = () => {
		return (
			<Grid container>
				<Typography variant="h3" className={styles.existingUserEligibleText}>
					An error occurred while looking up your account.
				</Typography>
				<Grid item>
					<div className={styles.existingUserBanner} id={"existingUserLoggedIn"}>
						<div className={styles.eligibleIcon}>
							<ErrorIcon sx={{fontSize: "60px"}}></ErrorIcon>
						</div>
						<div className={styles.instructionsText}>
							<Typography className={styles.instructionsText}>
								An error occurred looking up your account. Please try again.
								If this error continues, please contact {PROVIDER_NAME} at {PROVIDER_PHONE} or {PROVIDER_EMAIL}.
								If you want to create a new account, click the Back to Home button and apply.
							</Typography>
						</div>
					</div>
				</Grid>
				<Grid item>

				</Grid>
			</Grid>
		);
	};
	
	return (
		<Grid sx={{ textAlign: "left" }}>
			<SectionHeading> This process links your previous account's history with your new system.</SectionHeading>
			<form>
				<Grid container spacing={2}>
					<GridText
						label={"Email Address"}
						value={email}
						onChange={e => setEmail(e)}
						required
						columnWidth={halfWidth}
						testElementName="tbEmail"
					/>
					<Grid item {...fullWidth}>
						<Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
							<Button component={RouterLink} variant={"contained"} color={"secondary"} to={`/${tenant}/pages/landing`} >Back to Home</Button>
							<Button disabled={!!registrationResult} variant={"contained"} color={"primary"} onClick={registerClient}>Create Account</Button>
						</Stack>
					</Grid>
				</Grid>
			</form>
			{registrationResult?.isNewUser && <AccountLinked/>}
			{registrationResult?.isExistingUser && <AccountExists/>}
			{registrationResult && registrationResult.accountNotFound && <AccountNotFound/>}
			{registrationResult?.hasError && <ErrorResponse/>}
		</Grid>
	);
}

export default MigratingClientRegistration;
