import {useEffect} from "react";

// project imports
import useAuth from "hooks/useAuth";
import {GuardProps} from "types";
import api from "library/api";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
	const {user, isLoggedIn, login} = useAuth();

	useEffect(() => {
		if (!isLoggedIn )
			login();
	}, [isLoggedIn, login]);

	useEffect(() => {
		api.Users.set(user);
	}, [user]);

	return isLoggedIn ? children : <></>;
};

export default AuthGuard;