import { lazy } from "react";
import {useRoutes} from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import Loadable from "ui-component/Loadable";

const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
	return useRoutes([{ path: "/:tenant", element: <PagesLanding /> }, MainRoutes, AuthenticationRoutes]);
}
