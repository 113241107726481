import {FC, ReactNode} from "react";
import { Typography } from "@mui/material";

const SectionHeading: FC<{marginTop?: number, marginBottom?: number, children?: string}> = ({marginBottom, marginTop, children}) =>
	<Typography variant="h2" gutterBottom sx={{mb: marginBottom ?? 2, mt: marginTop ?? 0, textAlign: "left"}}>
		{children}
	</Typography>;
	
const SectionSubHeading: FC<{marginTop?: number, marginBottom?: number, children?: ReactNode}> = ({marginTop, marginBottom, children}) =>
	<Typography variant="h4" gutterBottom sx={{ mb: marginBottom ?? 2, mt: marginTop ?? 5, textAlign: "left" }}>
		{children}
	</Typography>;

export {SectionHeading, SectionSubHeading};