enum Gender {
	Male = "Male",
	Female = "Female",
	NonBinary = "Non-binary",
	PreferNotToSay = "Prefer Not To Say"
}

enum CitizenStatus {
	Citizen = "Canadian Citizen",
	PermanentResident = "Permanent Resident",
	Immigrant = "Sponsored Immigrant",
	Refugee = "Refugee",
	RefugeeClaimant = "Refugee Claimant",
	Indigenous = "First Nation(s) / Indigenous",
	DeportationOrder = "Deportation Order",
	Metis = "Metis",
	Inuit = "Inuit",
	UkrainianEvacuee = "Ukrainian Evacuee",
	Other = "Other",
}

enum Bedrooms {
	Bachelor = "Bachelor",
	One = "1 bedroom",
	Two = "2 bedroom",
	Three = "3 bedroom",
	FourPlus = "4 or more bedrooms",
}

enum LivingSituation {
	coOwn = "Co-Own",
	own = "Own",
	Rent = "Rent",
	WithFamily = "Staying with friend or relative",
	Temporary = "Temporary",
	Homeless = "Homeless"
}

enum YesNo {
	Yes = "Yes",
	No = "No",
}

enum FileProcess {
	Idle,
	Uploading,
	Deleting
}

function yesNoUnsureFrom (value: YesNoUnsure | undefined): YesNoUnsure | undefined {
	switch(value) {
		case YesNoUnsure.Yes:
		case YesNoUnsure.No:
			return value;
		case YesNoUnsure.Unsure:
		case null:
			return YesNoUnsure.Unsure;
		default:
			return undefined;
	}
}

enum YesNoUnsure {
	Yes = "Yes",
	No = "No",
	Unsure = "Unsure"
}
function yesNoSilentFrom (value: YesNoSilent | undefined): YesNoSilent | undefined {
	switch(value) {
		case YesNoSilent.Yes:
		case YesNoSilent.No:
			return value;
		case YesNoSilent.PreferNotToSay:
		case null:
			return YesNoSilent.PreferNotToSay;
		default:
			return undefined;
	}
}
enum YesNoSilent {
	Yes = "Yes",
	No = "No",
	PreferNotToSay = "Prefer not to say"
}

enum StudentStatus {
	notStudent = "NotAStudent",
	partTime = "Part Time",
	fullTime = "Full Time"
}

enum PhoneNumberType {
	mobile = "Mobile",
	home = "Home",
	work = "Work",
}

enum RelationToApplicant {
	spouse = "Spouse / Partner",
	parent = "Parent",
	sibling = "Sibling",
	child = "Child",
	grandchild = "Grandchild",
	grandparent = "Grandparent",
	otherRelative = "Other Relative",
	friend = "Friend",
	roommate = "Roommate",
	other = "Other",
}

enum IncomeFrequency {
	Weekly = "Weekly",
	BiWeekly = "Bi-Weekly",
	Monthly = "Monthly",
	Annually = "Annually"
}

enum HouseholdMembersType {
	ADULTS = "adults",
	DEPENDANTS = "dependants",
}

enum LoadStatus {
	Loaded,
	Loading,
	DoesNotExist
}

enum ApplicationStatus {
	Draft = "Draft",
	Generated = "Generated",
	Submitted = "Submitted, Pending Initial Review",
	AwaitingDocuments = "Awaiting Supporting Documents",
	PendingApproval = "Supporting Documents Received",
	Approved = "Approved",
}

enum ApplicationType {
	Standard = "Standard",
	AnnualReview = "AnnualReview",
	InterimReview = "InterimReview"
}

enum NotificationAction {
	NoAction,
	UploadSupportingDocuments,
	BeginApplication,
	EditApplication,
	ContinueApplication,
	BeginAnnualReview,
	CreateAnnualReview,
	ContinueAnnualReview,
	EditSupportingDocuments,
    EditAnnualReview,
	ContinueInterimReview,
	EditInterimReview
}

enum ClientStatus {
	Active = "Active",
	Inactive = "Inactive",
	Suspended = "Suspended",
	Extend = "Extend"
}

export {
	Gender,
	CitizenStatus,
	Bedrooms,
	LivingSituation,
	YesNo,
	YesNoUnsure,
	YesNoSilent,
	StudentStatus,
	PhoneNumberType,
	RelationToApplicant,
	IncomeFrequency,
	HouseholdMembersType,
	LoadStatus,
	ApplicationStatus,
	ApplicationType,
	yesNoSilentFrom,
	yesNoUnsureFrom,
	FileProcess,
	NotificationAction,
	ClientStatus
};