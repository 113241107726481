import { CitizenStatus, LivingSituation, YesNo } from "../enums";
import { createErrorState, ErrorState, IErrorState } from "../errorState";
import {Email} from "../types";
import {isEmpty, isValidEmail} from "../common";

interface IScreeningInformation {
	citizenship: CitizenStatus;
	assetsAboveThreshold: YesNo;
	householdIncome: number;
	livingSituation: LivingSituation;
	signedLease: YesNo;
	email:Email;
}

class ScreeningInformation implements Partial<IScreeningInformation>, IErrorState<Partial<IScreeningInformation>> {
	readonly assetsAboveThreshold?: YesNo;
	readonly citizenship?: CitizenStatus;
	readonly householdIncome?: number;
	readonly livingSituation?: LivingSituation;
	readonly signedLease?: YesNo;
	readonly email?: Email;
	private readonly validated: boolean;


	constructor(from?: Partial<IScreeningInformation>) {
		this.assetsAboveThreshold = from?.assetsAboveThreshold;
		this.citizenship = from?.citizenship;
		this.householdIncome = from?.householdIncome;
		this.livingSituation = from?.livingSituation;
		this.signedLease = from?.signedLease;
		this.validated = (from as ScreeningInformation)?.validated ?? false;
		this.email = from?.email;
	}

	get errorState(): ErrorState<Partial<IScreeningInformation>> {
		return this.validated
			? createErrorState<IScreeningInformation>({
				citizenship: this.citizenship === undefined ? "Citizenship required." : "",
				assetsAboveThreshold: this.assetsAboveThreshold === undefined ? "Assets required." : "",
				householdIncome: this.householdIncome === undefined
					? "Household income required." 
					: this.householdIncome > 1_000_000 ? "Please enter a value less than $1,000,000." : "",
				signedLease: this.signedLease === undefined ? "Signed Lease answer required." : "",
				email: isEmpty(this.email) ? "Email address is required" : !isValidEmail(this?.email?.address) ? "Invalid email" : ""
			}) : {};
	}
    
	validate(): ScreeningInformation {
		return new ScreeningInformation({...this, validated: true});
	}
}

export {ScreeningInformation};
export type {IScreeningInformation};