import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IntakeBuilder} from "library";
import api from "library/api";

const set = (existing: object | undefined, value: Partial<IntakeBuilder>): IntakeBuilder => {
	return new IntakeBuilder({...existing ?? {}, ...value ?? {}});
};

const submitScreeningResults = createAsyncThunk("intake/submit", async (value: IntakeBuilder) => {
	return await api.Screening.submit(value);
});

interface IIntake {
	loading: boolean;
	value: IntakeBuilder;
}

const intake = createSlice({
	name: "intakeState",
	initialState: {loading: false, value: new IntakeBuilder()} as IIntake,
	reducers: {
		setIntake: (state, {payload}) => ({loading: false, value: set(state, payload)}),
		reset: () => ({loading: false, value: set(undefined, {})})
	},
	extraReducers: builder => {
		builder.addCase(submitScreeningResults.pending, (state) => ({...state, loading: true}));
		builder.addCase(submitScreeningResults.fulfilled, (state, {payload}) =>
			({
				loading: false,
				value: set(state.value, {id: payload.id, ineligibleReasons: payload.ineligibleReasons, isNewUser: payload.isNewUser, hasApplicationInProgress: payload.hasApplicationInProgress})
			}));
		builder.addCase(submitScreeningResults.rejected, (state) => ({...state, loading: false}));
	}
});

export default intake.reducer;

export {submitScreeningResults};
export const { setIntake, reset } = intake.actions;