import { FC } from "react";
import { Card, CardContent, Grid } from "@mui/material";

const FormContainer: FC<{children?: JSX.Element}> = ({children}) => {    
	return (
		<Grid item xs={12}>
			<Card sx={{ mb: 6.25 }} elevation={4}>
				<CardContent sx={{ p: 4 }}>
					{children}
				</CardContent>
			</Card>
		</Grid>
	);
};

export { FormContainer };
