import {Person, Intake, ScreeningInformation, IPerson, IScreeningInformation } from "./";
import {ErrorState, IErrorState, createErrorState} from "../errorState";

class IntakeBuilder implements IErrorState<Partial<Person & ScreeningInformation>> {
	readonly id?: string;
	readonly basicInformation?: Person;
	readonly screeningInformation?: ScreeningInformation;
	readonly ineligibleReasons?: string[];
	private readonly validated: boolean;
	readonly isNewUser?: boolean;
	readonly hasApplicationInProgress?: boolean;
    
	constructor(from?: Partial<IntakeBuilder>) {
		this.id = from?.id;
		this.basicInformation = from?.basicInformation;
		this.screeningInformation = from?.screeningInformation;
		this.ineligibleReasons = from?.ineligibleReasons;
		this.validated = (from as IntakeBuilder)?.validated ?? false;
		this.isNewUser = from?.isNewUser;
		this.hasApplicationInProgress = from?.hasApplicationInProgress;

		if (!this.validated) return;

		this.basicInformation = new Person(this.basicInformation).validate();
		this.screeningInformation = new ScreeningInformation(this.screeningInformation).validate();
	}

	get isEligible(): boolean {
		return !!this.ineligibleReasons && !this.ineligibleReasons.length && !this.hasApplicationInProgress;
	}
    
	build(): Intake | undefined {
		return this.isEligible
			? new Intake(this.id!, this.isNewUser ?? true, {...this.basicInformation, ...this.screeningInformation,...{validated: false}})
			: undefined;
	}
    
	get errorState(): ErrorState<Partial<IPerson & IScreeningInformation>> {
		return this.validated ? createErrorState<Partial<IPerson & IScreeningInformation>>({
			...this.basicInformation?.errorState,
			...this.screeningInformation?.errorState,
			studentStatus: undefined,
		}) : {};
	}
    
	validate(): IntakeBuilder {
		return new IntakeBuilder({...this, validated: true});
	}
}

export {
	IntakeBuilder
};