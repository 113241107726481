import {createErrorState, ErrorState, IErrorState} from "../errorState";
import {Email, Phone} from "../types";
import {matchIsValidTel} from "mui-tel-input";

interface IContact {
	name: string;
	organization?: string;
	phone?: Phone;
	email?: Email;
	relationship?: string;
	displayName?:string;
}

class Contact implements Partial<IContact>, IErrorState<Partial<IContact>> {
	readonly name?: string;
	readonly organization?: string;
	readonly phone?: Phone;
	readonly email?: Email;
	readonly relationship?: string;
	private readonly validated: boolean;
	readonly displayName?: string;
	
	constructor(from?: Partial<Contact>) {
		this.name = from?.name;
		this.organization = from?.organization;
		this.phone = from?.phone;
		this.email = from?.email;
		this.relationship = from?.relationship;
		this.validated = (from as Contact)?.validated ?? false;
		this.displayName = this.name;
	}
	
	validate(): Contact {
		return new Contact({...this, validated: true});
	}
	
	get errorState(): ErrorState<Partial<Contact>> {
		return this.validated ? createErrorState<Contact>({
			name: !this.name ? "Individual name required." : "",
			phone: !this.phone?.number 
				? "Phone required."
				: matchIsValidTel(this.phone.number?.replaceAll(" ", "")) ? "" : "Invalid phone number."			
		}) : {};
	}
}

interface IApplicationContacts {
	emergencyContactOne: Contact;
	emergencyContactTwo?: Contact;
	requireFollowup: boolean;	
	contacts: Contact[];
}

class ApplicationContacts implements Partial<IApplicationContacts>, IErrorState<Partial<IApplicationContacts>> {
	[key: string]: unknown;

	readonly emergencyContactOne: Contact;
	readonly emergencyContactTwo?: Contact;
	readonly requireFollowup?: boolean;
	readonly contacts?: Contact[];
	private readonly validated: boolean;
	
	constructor(from?: Partial<ApplicationContacts>) {		
		this.authorizeCommunications = from?.authorizeCommunications ?? false;

		this.emergencyContactOne = new Contact(from?.emergencyContactOne);
		this.emergencyContactTwo = new Contact(from?.emergencyContactTwo);
		this.requireFollowup = from?.requireFollowup ?? false;
		this.contacts = from?.contacts?.map(c => new Contact({...c, validated: false} as any)) ?? [new Contact(), new Contact()];
		this.validated = (from as ApplicationContacts)?.validated ?? false;
		
		if (!this.requireFollowup || !this.validated) return;
		
		this.contacts = this.contacts.map((c, i) => i ? c : c.validate());
	}
	
	get errorState(): ErrorState<Partial<IApplicationContacts>> {
		return {};
	}
	
	validate(): ApplicationContacts {
		return new ApplicationContacts({...this, validated: true});
	}
}

export {ApplicationContacts, Contact};
export type {IApplicationContacts, IContact};