import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "library/api";
import { HousingBody } from "library/civida/managedCities";

const getHousingBodies = createAsyncThunk("housingBodies/get", async () => {
	return await api.HousingBodies.get();
});

interface IHousingBodies {
	loading: boolean;
	housingBodies: HousingBody[];
}

const initialState: IHousingBodies = {
	loading: true,
	housingBodies: []
};

const housingBodies = createSlice({
	name: "housingBodies",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getHousingBodies.pending, (state) => ({...state, loading: true}));
		builder.addCase(getHousingBodies.fulfilled, (state, {payload}) => ({...state, loading: false, housingBodies: payload}));
		builder.addCase(getHousingBodies.rejected, (state) => ({...state, loading: false}));
	}
});

export default housingBodies.reducer;

export {getHousingBodies};